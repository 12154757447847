import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { ComponentsModule } from '../components/components.module';
import { ImagelinksComponent } from './imagelinks/imagelinks.component';
import { PortfolioComponent } from './portfolio.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    RouterModule,
    ComponentsModule
  ],
  declarations: [
    PortfolioComponent,
    ImagelinksComponent
  ],
  exports:[ PortfolioComponent, ImagelinksComponent ],
  providers: []
})
export class PortfolioModule { }
