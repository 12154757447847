import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

import { HomeComponent } from './home.component';
import { LandingComponent } from './landing/landing.component';

import { ComponentsModule } from '../components/components.module';
import { ResumeModule } from '../resume/resume.module';
import { ContactModule } from '../contact/contact.module';
import { PortfolioModule } from '../portfolio/portfolio.module';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        RouterModule,
        ComponentsModule,
        ResumeModule,
        ContactModule,
        PortfolioModule
        
    ],
    declarations: [ 
        HomeComponent,
        LandingComponent
    ],
    exports:[ HomeComponent, LandingComponent ],
    providers: []
})
export class HomeModule { }
