import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { HomeComponent } from "./home/home.component";
import { ResumeComponent } from "./resume/resume.component";
import { ProfileComponent } from "./profile/profile.component";
import { SignupComponent } from "./signup/signup.component";
import { LandingComponent } from "./home/landing/landing.component";
import { NucleoiconsComponent } from "./components/nucleoicons/nucleoicons.component";
import { ProjectCreamComponent } from "./projects/project-cream/project-cream.component";
import { ProjectBimComponent } from "./projects/project-bim/project-bim.component";
import { ProjectAuthentificationComponent } from "./projects/project-authentification/project-authentification.component";
import { ProjectTwitchComponent } from "./projects/project-twitch/project-twitch.component";

const routes: Routes = [
  { path: "home", component: HomeComponent },
  { path: "projects/cream", component: ProjectCreamComponent },
  { path: "projects/bim", component: ProjectBimComponent },
  {
    path: "projects/authentication",
    component: ProjectAuthentificationComponent
  },
  { path: "projects/twitch", component: ProjectTwitchComponent },
  { path: "**", redirectTo: "home" }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      onSameUrlNavigation: "reload",
      scrollOffset: [0, 64] // [x, y]
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
