import { Component, OnInit } from '@angular/core';
import { log } from 'util';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
    model = {
        left: true,
        middle: false,
        right: false,
        hover: false,
        
    };

    focus;
    focus1;
    constructor() { }

    isHovering = false;
    isHovering1 = false;
    isHovering2 = false;
    isHovering3 = false;

    
    //1---
    mouseHovering() {
        this.isHovering = true;
        console.log('enter -> ' + this.isHovering);
    }

    mouseLeaving() {
        this.isHovering = false;
        console.log('exit -> ' + this.isHovering);
    }

    //2---
    mouseHovering1() {
        this.isHovering1 = true;
        console.log('enter -> ' + this.isHovering1);
    }

    mouseLeaving1() {
        this.isHovering1 = false;
        console.log('exit -> ' + this.isHovering1);
    }
     //3---
     mouseHovering2() {
        this.isHovering2 = true;
        console.log('enter -> ' + this.isHovering2);
    }

    mouseLeaving2() {
        this.isHovering2 = false;
        console.log('exit -> ' + this.isHovering2);
    }
     //4---
     mouseHovering3() {
        this.isHovering3 = true;
        console.log('enter -> ' + this.isHovering3);
    }

    mouseLeaving3() {
        this.isHovering3 = false;
        console.log('exit -> ' + this.isHovering3);
    }

    scroll(el: HTMLElement) {
        el.scrollIntoView();
        console.log('navigate to link')
    }


    ngOnInit() {
    }
}
