import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-imagelinks',
  templateUrl: './imagelinks.component.html',
  styleUrls: ['./imagelinks.component.scss']
})
export class ImagelinksComponent implements OnInit {
  focus: any;
  focus1: any;

  constructor() { }
  
    hoveringId: any;

    hoveringStyle(id: number) {
        this.hoveringId = id;
        console.log('enter -> ' + this.hoveringId);
    }

    nothoveringStyle() {
        this.hoveringId = null;
        console.log('enter -> ' + this.hoveringId);
    }

  
    ngOnInit() {
    }
}



