import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';
import { SignupComponent } from './signup/signup.component';
import { ProfileComponent } from './profile/profile.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import { ImagelinksComponent } from './portfolio/imagelinks/imagelinks.component';

import { HomeModule } from './home/home.module';
import { ResumeModule } from './resume/resume.module'
import { ContactModule } from './contact/contact.module';
import { PortfolioModule } from './portfolio/portfolio.module';
import { ProjectBimComponent } from './projects/project-bim/project-bim.component';
import { ProjectAuthentificationComponent } from './projects/project-authentification/project-authentification.component';
import { ProjectTwitchComponent } from './projects/project-twitch/project-twitch.component';
import { ProjectCreamComponent } from './projects/project-cream/project-cream.component';


@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    ProfileComponent,
    NavbarComponent,
    FooterComponent,
    ProjectBimComponent,
    ProjectAuthentificationComponent,
    ProjectTwitchComponent,
    ProjectCreamComponent 
    
  ],
  imports: [
    BrowserModule,
    NgbModule.forRoot(),
    FormsModule,
    RouterModule,
    AppRoutingModule,
    HomeModule,
    ResumeModule,
    ContactModule,
    PortfolioModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

